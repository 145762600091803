import {
  AnunciantesIcon,
  CampanhasIcon,
  DashboardIcon,
  // RelatoriosIcon,
} from '~/shared';
// import { GerenciamentoIcon } from '~/shared/icons/Gerenciamento';

export const navList = [
  {
    label: 'Dashboard',
    icon: <DashboardIcon />,
    href: '/dashboard',
  },
  {
    label: 'Campanhas',
    icon: <CampanhasIcon />,
    href: '/campanhas',
  },
  {
    label: 'Anunciantes',
    icon: <AnunciantesIcon />,
    href: '/anunciantes',
  },
  // {
  //   label: 'Relatorios',
  //   icon: <RelatoriosIcon />,
  //   href: '/relatorios',
  // },
  // {
  //   label: 'Usuarios',
  //   icon: <GerenciamentoIcon />,
  //   href: '/gerenciamento',
  // },
  // {
];
